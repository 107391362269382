import type { FC } from 'react'
import type { TButtonProps } from './type'

const Button: FC<TButtonProps> = ({ className }) => (
  <a
    className={`center mx-6 py-5 px-20 bg-[#10212f] rounded-md uppercase text-2xl tracking-wider font-bold ${
      className ? className : ''
    }`}
    href='#order_section'
  >
    Купить сейчас
  </a>
)

export default Button
